import React, { useEffect, useState } from 'react';
import { auth } from './firebase/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { AuthProvider, signInWithPopup } from 'firebase/auth';
import { HouseholdType, ShoppingListType, StateStatus } from './app.model';
import useHouseholdsData from './hooks/useHouseholdsData';
import useShoppingListsData from './hooks/useShoppingListsData';
import InvitationForm from './components/InvitationForm';
import LoadingSpinner from './components/UI/LoadingSpinner';
import LoginButton from './components/UI/LoginButton';
import ListOfLists from './components/ShoppingList/ListOfLists';

function App() {
  const [user, authInitializing] = useAuthState(auth);
  const [householdsState, getHouseholds, resetHouseholds] = useHouseholdsData();
  const [lists, getLists] = useShoppingListsData();

  const [selectedHousehold, setSelectedHousehold] =
    useState<HouseholdType | null>(null);
  const [selectedList, setSelectedList] = useState<ShoppingListType | null>(
    null
  );

  const { households, status, isLoading: householdsLoading } = householdsState;

  useEffect(() => {
    if (user) {
      getHouseholds(user.uid);
    } else {
      resetHouseholds();
    }
  }, [getHouseholds, user, resetHouseholds]);

  useEffect(() => {
    if (households.length > 0) {
      getLists(households[0].id);
      setSelectedHousehold(households[0]);
    } else {
      setSelectedHousehold(null);
    }
  }, [households, getLists, householdsLoading]);

  useEffect(() => {
    if (lists.length > 0) {
      setSelectedList(lists[0]);
    } else {
      setSelectedList(null);
    }
  }, [lists]);

  const signInHandler = (provider: AuthProvider) => {
    signInWithPopup(auth, provider);
  };

  const isLoading = householdsLoading || authInitializing;

  return (
    <>
      {!user && !authInitializing && <LoginButton onLogin={signInHandler} />}
      {isLoading && <LoadingSpinner />}

      {/* {selectedHousehold && selectedList && (
          <ShoppingList
            householdId={selectedHousehold.id}
            list={selectedList}
          />
        )} */}

      {selectedHousehold && selectedList && (
        <ListOfLists householdId={selectedHousehold.id} />
      )}

      {households.length <= 0 &&
        status !== StateStatus.EMPTY &&
        user &&
        !isLoading && <InvitationForm userId={user.uid} />}
    </>
  );
}

export default App;
