import React from 'react';
import { ListItemType } from '../../app.model';
import { BsXLg } from 'react-icons/bs';

import styles from './ShoppingListItem.module.scss';
import ListItem from '../UI/ListItem';

interface Props {
  item: ListItemType;
  onCartClick: (id: string, inBasket: boolean) => void;
  onRemoveClick: (id: string) => void;
}

const ShoppingListItem: React.FC<Props> = ({
  item,
  onCartClick,
  onRemoveClick,
}) => {
  const cartClickHandler = () => {
    onCartClick(item.id, !item.inBasket);
  };

  const deleteClickHandler = () => {
    onRemoveClick(item.id);
  };

  return (
    <ListItem className={item.inBasket ? styles['in-basket'] : ''}>
      <div className={styles.container}>
        <div className={styles.name}>{item.name}</div>
        <div className={styles.actions}>
          <BsXLg onClick={deleteClickHandler} />
          <span onClick={cartClickHandler}>
            <input
              className={styles.checkbox}
              type="checkbox"
              checked={item.inBasket}
              readOnly
            />
          </span>
        </div>
      </div>
    </ListItem>
  );
};

export default ShoppingListItem;
