import React from 'react';

import styles from './Button.module.scss';

interface OwnProps {
  type: 'submit' | 'button' | 'reset' | undefined;
  secondary?: boolean;
}

type Props = OwnProps & React.HTMLProps<HTMLButtonElement>;

const Button: React.FC<Props> = (props) => {
  let className = `${styles.button} ${props.className || ''}`;

  if (props.secondary) {
    className = `${className} ${styles.secondary}`;
  }

  return (
    <button {...props} type={props.type} className={className}>
      {props.children}
    </button>
  );
};

export default Button;
