import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyBl3_2khSZ_YlRNQ-6JULiCWrPikhc3MFs',
  authDomain: 'shopping-list-4e13b.firebaseapp.com',
  projectId: 'shopping-list-4e13b',
  storageBucket: 'shopping-list-4e13b.appspot.com',
  messagingSenderId: '5270986238',
  appId: '1:5270986238:web:d1feb956e351d4e17774af',
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const firestore = getFirestore(app);
