import React, { ChangeEvent, FormEvent, useState } from 'react';
import useInvitationSubmit from '../hooks/useInvitationSubmit';

import styles from './InvitationForm.module.scss';
import Button from './UI/Button';

interface Props {
  userId: string;
}

const InvitationForm: React.FC<Props> = ({ userId }) => {
  const [invitationCode, setInvitationCode] = useState<string>('');
  const submitInvitation = useInvitationSubmit();

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setInvitationCode(event.target.value);
  };

  const submitHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (invitationCode.trim().length > 0) {
      submitInvitation(invitationCode, userId);
      setInvitationCode('');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <p>Uh-oh!</p>
        <p>
          It looks like you don't have access to any shopping list right now.{' '}
        </p>
        <p>Please enter invitation code:</p>
      </div>
      <form onSubmit={submitHandler}>
        <input
          placeholder="INVITATION CODE"
          type="text"
          value={invitationCode}
          onChange={changeHandler}
          className={styles['code-input']}
        />
        <Button className={styles['submit-button']} type="submit">
          Submit
        </Button>
      </form>
    </div>
  );
};

export default InvitationForm;
