import React from 'react';
import Card from '../UI/Card';

import styles from './ListItem.module.scss';

interface Props {
  className?: string;
  onClick?: () => void;
}

const ListItem: React.FC<Props> = ({ children, className, onClick }) => {
  return (
    <li onClick={onClick}>
      <Card className={`${styles['list-item']} ${className || ''}`}>
        {children}
      </Card>
    </li>
  );
};

export default ListItem;
