import {
  collection,
  FirestoreError,
  addDoc,
  Timestamp,
  query,
  orderBy,
  doc,
  deleteDoc,
  updateDoc,
  getDoc,
} from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { ListItemType } from '../app.model';
import { firestore } from '../firebase/firebase';

const useItemsQuery = (
  householdId: string,
  listId: string
): [
  string,
  ListItemType[],
  boolean,
  FirestoreError | undefined,
  (item: { name: string }) => void,
  (id: string) => Promise<void>,
  (
    id: string,
    params: {
      [key: string]: any;
    }
  ) => Promise<void>
] => {
  const itemsRef = collection(
    firestore,
    'households',
    householdId,
    'lists',
    listId,
    'items'
  );

  const [value, loading, error] = useCollection(
    query(itemsRef, orderBy('createdAt'))
  );
  const [listName, setListName] = useState('');

  const getListName = useCallback(async () => {
    const listRef = doc(firestore, 'households', householdId, 'lists', listId);

    const listSnapshot = await getDoc(listRef);

    return listSnapshot.data()!.name as string;
  }, [householdId, listId]);

  useEffect(() => {
    getListName().then(setListName);
  }, [getListName]);

  const removeItem = async (id: string) => {
    const docRef = doc(
      firestore,
      'households',
      householdId,
      'lists',
      listId,
      'items',
      id
    );

    await deleteDoc(docRef);
  };

  const updateItem = async (id: string, params: { [key: string]: any }) => {
    const docRef = doc(
      firestore,
      'households',
      householdId,
      'lists',
      listId,
      'items',
      id
    );

    await updateDoc(docRef, params);
  };

  const addItem = async (item: { name: string }) => {
    await addDoc(itemsRef, {
      ...item,
      createdAt: Timestamp.now(),
      inBasket: false,
    });
  };

  const items: ListItemType[] = value
    ? value.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name as string,
        inBasket: doc.data().inBasket as boolean,
      }))
    : [];

  return [listName, items, loading, error, addItem, removeItem, updateItem];
};

export default useItemsQuery;
