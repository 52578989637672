import React from 'react';
import Button from '../Button';
import Modal from './Modal';

import styles from './ConfirmationModal.module.scss';

interface Props {
  label: string;
  confirmLabel?: string;
  cancelLabel?: string;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmationModal: React.FC<Props> = (props) => {
  return (
    <Modal onBackdropClick={props.onClose}>
      <p className={styles.label}>{props.label}</p>
      <div className={styles.actions}>
        <Button secondary type="button" onClick={props.onClose}>
          No
        </Button>
        <Button type="button" onClick={props.onConfirm}>
          Yes
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
