import React from 'react';
import ReactDOM from 'react-dom';

import styles from './Modal.module.scss';

interface BackdropProps {
  onBackdropClick?: () => void;
}

const Backdrop: React.FC<BackdropProps> = (props) => {
  return <div onClick={props.onBackdropClick} className={styles.backdrop} />;
};

const ModalOverlay: React.FC = (props) => {
  return (
    <div className={styles.modal}>
      <div className={styles.content}>{props.children}</div>
    </div>
  );
};

const Modal: React.FC<BackdropProps> = (props) => {
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onBackdropClick={props.onBackdropClick} />,
        document.getElementById('overlays')!
      )}
      {ReactDOM.createPortal(
        <ModalOverlay>{props.children}</ModalOverlay>,
        document.getElementById('overlays')!
      )}
    </>
  );
};

export default Modal;
