import React from 'react';
import NewItemForm from '../ShoppingList/NewItemForm';

import styles from './TopBar.module.scss';

interface Props {
  onFormSubmit: (value: string) => void;
  formPlaceholder: string;
}

const TopBar: React.FC<Props> = ({
  children,
  onFormSubmit,
  formPlaceholder,
}) => {
  return (
    <div className={styles['top-bar']}>
      <div className={styles['children-container']}>{children}</div>
      <NewItemForm placeholder={formPlaceholder} onAddItem={onFormSubmit} />
    </div>
  );
};

export default TopBar;
