import React, { ChangeEvent, FormEvent, useState } from 'react';

import styles from './NewItemForm.module.scss';
import Button from '../UI/Button';

interface Props {
  onAddItem: (item: string) => void;
  placeholder: string;
}

const NewItemForm: React.FC<Props> = ({ onAddItem, placeholder }) => {
  const [itemName, setItemName] = useState<string>('');

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setItemName(event.target.value);
  };

  const submitHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (itemName.trim().length > 0) {
      onAddItem(itemName);
      setItemName('');
    }
  };

  return (
    <form className={styles['new-item-form']} onSubmit={submitHandler}>
      <input
        placeholder={placeholder}
        type="text"
        value={itemName}
        onChange={changeHandler}
        className={styles['item-input']}
      />
      <Button type="submit">Add</Button>
    </form>
  );
};

export default NewItemForm;
