import { collection, query, where, getDocs } from 'firebase/firestore';
import { useReducer } from 'react';
import { HouseholdType, StateStatus } from '../app.model';
import { firestore } from '../firebase/firebase';
import { useCallback } from 'react';

export interface HouseholdState {
  status: StateStatus;
  households: HouseholdType[];
  isLoading: boolean;
}

const initialState = {
  status: StateStatus.EMPTY,
  households: [],
  isLoading: false,
};

type HouseholdAction =
  | { type: 'FETCH_START' }
  | { type: 'FETCH_SUCCESS'; payload: HouseholdType[] }
  | { type: 'RESET' };

const reducer = (
  state: HouseholdState,
  action: HouseholdAction
): HouseholdState => {
  switch (action.type) {
    case 'FETCH_START':
      return {
        ...state,
        status: StateStatus.LOADING,
        isLoading: true,
      };
    case 'FETCH_SUCCESS':
      return {
        households: action.payload,
        status: StateStatus.LOADED,
        isLoading: false,
      };
    case 'RESET': {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};

const useHouseholdsData = (): [
  HouseholdState,
  (userId: string) => Promise<void>,
  () => void
] => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const get = useCallback(async (userId: string) => {
    dispatch({ type: 'FETCH_START' });
    const householdRef = collection(firestore, 'households');

    const householdQuery = query(
      householdRef,
      where('users', 'array-contains', userId)
    );

    const householdSnapshot = await getDocs(householdQuery);

    const households = householdSnapshot.docs.map((doc) => ({
      id: doc.id,
      name: doc.data().name as string,
    }));

    dispatch({ type: 'FETCH_SUCCESS', payload: households });
  }, []);

  const reset = useCallback(() => {
    dispatch({ type: 'RESET' });
  }, []);

  return [state, get, reset];
};

export default useHouseholdsData;
