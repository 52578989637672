import React from 'react';
import Button from '../UI/Button';

import styles from './ClearListButton.module.scss';

interface Props {
  onClick: () => void;
  allItemsCount: number;
  addedItemsCount: number;
}

const ClearListButton: React.FC<Props> = (props) => {
  return (
    <Button
      onClick={props.onClick}
      className={styles['clear-button']}
      type="button"
    >
      Clear ({props.addedItemsCount}/{props.allItemsCount})
    </Button>
  );
};

export default ClearListButton;
