export enum StateStatus {
  EMPTY,
  LOADING,
  LOADED,
}

export interface HouseholdType {
  id: string;
  name: string;
}

export interface ShoppingListType {
  id: string;
  name: string;
}

export interface ListItemType {
  id: string;
  name: string;
  inBasket: boolean;
}
