import { arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../firebase/firebase';

const useInvitationSubmit = () => {
  const submitInvitation = async (code: string, uid: string) => {
    const householdRef = doc(firestore, 'households', code);

    await updateDoc(householdRef, {
      users: arrayUnion(uid),
    });
  };

  return submitInvitation;
};

export default useInvitationSubmit;
