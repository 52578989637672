import { AuthProvider, GoogleAuthProvider } from 'firebase/auth';
import React from 'react';
import Button from './Button';

import styles from './LoginButton.module.scss';

interface Props {
  onLogin: (provider: AuthProvider) => void;
}

const LoginButton: React.FC<Props> = ({ onLogin }) => {
  const clickHandler = () => {
    const provider = new GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    onLogin(provider);
  };

  return (
    <Button
      className={styles['login-button']}
      type="button"
      onClick={clickHandler}
    >
      Sign in with Google!
    </Button>
  );
};

export default LoginButton;
