import React, { useEffect, useState } from 'react';
import { BsHouse, BsListTask, BsTrash } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import useShoppingListsData from '../../hooks/useShoppingListsData';
import ListItem from '../UI/ListItem';
import TopBar from '../UI/TopBar';

import styles from './ListOfLists.module.scss';
import ConfirmationModal from '../UI/Modal/ConfirmationModal';
import { ShoppingListType } from '../../app.model';

interface Props {
  householdId: string;
}

const ListOfLists: React.FC<Props> = ({ householdId }) => {
  const [lists, getLists, addShoppingList, removeShoppingList] = useShoppingListsData();
  const [listToRemove, setListToRemove] = useState<ShoppingListType | undefined>(undefined);

  useEffect(() => {
    getLists(householdId);
  }, [householdId, getLists]);

  const listAddHandler = (name: string) => {
    addShoppingList(householdId, { name }).then(() => getLists(householdId));
  };

  const trashClickHandler = (event: React.MouseEvent, list: ShoppingListType) => {
    event.preventDefault();
    setListToRemove(list);
  }

  const removeList = () => {
    removeShoppingList(householdId, listToRemove?.id || "").then(() => getLists(householdId))
    setListToRemove(undefined);
  }

  return (
    <div>
      <TopBar formPlaceholder="ADD NEW LIST" onFormSubmit={listAddHandler}>
        <BsHouse className={styles['house-icon']} />
        <p>Your lists</p>
      </TopBar>
      <ul className={styles.list}>
        {lists.map((list) => (
          <Link
            className={styles['list-link']}
            to={`hid/${householdId}/lid/${list.id}`}
            state={{ listName: list.name }}
            key={list.id}
          >
            <ListItem className={styles['list-card']}>
              <p>{list.name}</p>
              <div>
                <BsTrash className={styles['trash-icon']} onClick={(event) => trashClickHandler(event, list)} fontSize="4rem" />
                <BsListTask fontSize="4rem" />
              </div>
            </ListItem>
          </Link>
        ))}
      </ul>
      {listToRemove && (
        <ConfirmationModal
          label={`Remove list ${listToRemove.name}?`}
          cancelLabel="Cancel"
          confirmLabel="Proceed"
          onConfirm={removeList}
          onClose={() => {setListToRemove(undefined)}}
        />
      )}
    </div>
  );
};

export default ListOfLists;
