import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import ShoppingList from './components/ShoppingList/ShoppingList';
import Header from './components/UI/Header';

import styles from './App.module.scss';

const Routing: React.FC = () => {
  return (
    <div className={styles.app}>
      <Header />
      <section className={styles.container}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />}></Route>
            <Route
              path="/hid/:householdId/lid/:listId"
              element={<ShoppingList />}
            ></Route>
          </Routes>
        </BrowserRouter>
      </section>
    </div>
  );
};

export default Routing;
