import React, { useState } from 'react';
import useItemsQuery from '../../hooks/useItemsQuery';
import LoadingSpinner from '../UI/LoadingSpinner';
import ConfirmationModal from '../UI/Modal/ConfirmationModal';
import ClearListButton from './ClearListButton';
import ShoppingListItem from './ShoppingListItem';
import { BsArrowLeft } from 'react-icons/bs';

import styles from './ShoppingList.module.scss';
import { Link, useParams } from 'react-router-dom';
import TopBar from '../UI/TopBar';

const ShoppingList: React.FC = () => {
  const params = useParams();

  const [
    listName,
    items,
    itemsLoading,
    itemsError,
    addItem,
    removeItem,
    updateItem,
  ] = useItemsQuery(params.householdId!, params.listId!);

  const addedItemsCount = items.filter((item) => item.inBasket).length;

  const [showClearConfirm, setShowClearConfirm] = useState(false);

  const addItemHandler = (name: string) => {
    addItem({ name });
  };

  const crossOutItemHandler = (id: string, inBasket: boolean) => {
    updateItem(id, { inBasket });
  };

  const showClearConfirmModal = () => {
    if (addedItemsCount > 0) {
      setShowClearConfirm(true);
    }
  };

  const hideClearConfirmModal = () => {
    setShowClearConfirm(false);
  };

  const clearListHandler = () => {
    items
      .filter((item) => item.inBasket)
      .forEach((item) => {
        removeItem(item.id);
      });
    setShowClearConfirm(false);
  };

  const removeHandler = (id: string) => {
    removeItem(id);
  };

  return (
    <div className={styles['shopping-list']}>
      {itemsError && <p>{itemsError.message}</p>}
      {itemsLoading && <LoadingSpinner />}
      <TopBar onFormSubmit={addItemHandler} formPlaceholder="ADD ITEM">
        <Link className={styles['arrow-back']} to="/">
          <BsArrowLeft />
        </Link>
        <p className={styles['list-name']}>{listName}</p>
      </TopBar>
      {!itemsLoading && (
        <div>
          <ul className={styles['items-list']}>
            {items &&
              items.map((item) => (
                <ShoppingListItem
                  key={item.id}
                  onCartClick={crossOutItemHandler}
                  onRemoveClick={removeHandler}
                  item={item}
                />
              ))}
          </ul>
        </div>
      )}

      <ClearListButton
        allItemsCount={items.length}
        addedItemsCount={addedItemsCount}
        onClick={showClearConfirmModal}
      />
      {showClearConfirm && (
        <ConfirmationModal
          label="Are you sure that you want to clear already bought items?"
          cancelLabel="Cancel"
          confirmLabel="Proceed"
          onConfirm={clearListHandler}
          onClose={hideClearConfirmModal}
        />
      )}
    </div>
  );
};

export default ShoppingList;
