import {
  addDoc,
  collection,
  getDocs,
  orderBy,
  query,
  Timestamp,
  doc,
  deleteDoc
} from 'firebase/firestore';
import { useState } from 'react';
import { ShoppingListType } from '../app.model';
import { firestore } from '../firebase/firebase';
import { useCallback } from 'react';

const useShoppingListsData = (): [
  ShoppingListType[],
  (householdId: string) => Promise<void>,
  (
    householdId: string,
    list: {
      name: string;
    }
  ) => Promise<void>,
  (householdId: string, listId: string) => Promise<void>
] => {
  const [shoppingLists, setShoppingLists] = useState<ShoppingListType[]>([]);

  const get = useCallback(async (householdId: string) => {
    const listsRef = collection(firestore, 'households', householdId, 'lists');

    const householdSnapshot = await getDocs(
      query(listsRef, orderBy('createdAt'))
    );
    setShoppingLists(
      householdSnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name as string,
      }))
    );
  }, []);

  const addShoppingList = async (
    householdId: string,
    list: { name: string }
  ) => {
    const listsRef = collection(firestore, 'households', householdId, 'lists');

    await addDoc(listsRef, {
      ...list,
      createdAt: Timestamp.now(),
    });
  };

  const removeShoppingList = async (
    householdId: string,
    listId: string
  ) => {
    const docRef = doc(
      firestore,
      'households',
      householdId,
      'lists',
      listId
    );

    await deleteDoc(docRef);
  }

  return [shoppingLists, get, addShoppingList, removeShoppingList];
};

export default useShoppingListsData;
