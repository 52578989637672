import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../firebase/firebase';
import Button from './Button';

import styles from './Header.module.scss';

const Header: React.FC = () => {
  const [user] = useAuthState(auth);

  const signOutHandler = () => {
    auth.signOut();
  };

  return (
    <header>
      <h1 className={styles['main-header']}>
        Caramba shopping list{' '}
        {user && (
          <Button type="button" onClick={signOutHandler}>
            Log out
          </Button>
        )}
      </h1>
    </header>
  );
};

export default Header;
